$item-x-padding: 20px;
$navbar-height: 60px;
.navbar {
    position: relative;
    height: $navbar-height;
    box-shadow: $elevation-z8;
    z-index: 98;
    background: var(--bg-dark-blue);
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;
      }
ul.menu {
    max-height: 60px;
    float: left;
    padding-right: 45px;
    z-index: 99;
    > li {
      float: left;
      > div {
        > a,
        > div {
          border-bottom: 2px solid;
          height: 48px;
          box-sizing: border-box;
          border-color: transparent;
          margin: 0 6px;
        }
      }
    }
  }
  ul li {
    position: relative;
    margin: 0px;
    ul a {
      padding: 8px $item-x-padding;
      height: 48px;
    }
  }
  a,
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px $item-x-padding;
    height: $navbar-height;
    font-size: 0.875rem;
    text-decoration: none;
    box-sizing: border-box;
    color: $white;
    .material-icons {
      font-size: 21px;
      margin: 0 4px;
    }
  }


  ul ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background: var(--bg-dark-blue);
    /* has to be the same number as the "line-height" of "nav a" */
    left: $item-x-padding;
    box-shadow: $elevation-z8;
    top: 60px;
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
    z-index: -1;
  }

  ul li:hover > div > div > ul,
  ul li:hover > div > ul,
  li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
  }
  ul ul ul {
    top: 0;
    left: 170px;
  }
  ul ul ul li {
    position: relative;
    top: 0;
  }
  ul li ul li:hover,
  ul li ul li.open {
    background:rgb(18, 22, 39);
  }
  li > a:after {
    content: "arrow_drop_down";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size:25px;
    line-height: 1;
    margin-left: auto;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
  li > a:only-child:after {
    content: "";
  }
  .top-search-box{
    color: $white;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9;
    background-color:var(--bg-dark-blue);
    .search-box {
        outline: none;
        border: none;
        font-size: 1rem;
        height: calc(100% - 5px);
        background-color:var(--bg-dark-blue);
        color: $white;
        width: calc(100% - 50px);
    }
    }
    .btn-white{
        color: $white;
        &:hover{
            background-color: rgba(255, 255, 255, 0.1);
        } 
    }
    .MuiBadge-colorSecondary{
        color:var(--text-body);
        background-color:var(--secondary);
    }
}
.notification {
    width: $sidenav-width;
    .notification__topbar {
      height: $topbar-height;
      box-shadow: $elevation-z6;
    }
  
    .notification__card {
      &:hover {
        .delete-button {
          cursor: pointer;
          display: unset;
          right: 0;
          margin-top: 6px;
          top: 0;
          z-index: 2;
        }
        .card__topbar__time {
          display: none;
        }
      }
      .card__topbar {
      }
  
      .delete-button {
        display: none;
        position: absolute;
        right: 0;
        margin-top: 9px;
      }
      .card__topbar__button {
        height: 24px;
        width: 24px;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.9;
      }
    }
    .text-center {
        button{
            text-transform:capitalize;
            font-weight: 400;
            font-size: 14px;
        }
    }
  }
  .simple-menu-drak{
    .MuiPopover-paper{
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
      background-color:#222A45;
      ul{
        li{
           color: $white;
           min-height: auto;
           font-size:14px;
           &:hover{
            background-color: rgba(255, 255, 255, 0.1);
           }
        }
      }
    }
  }
  .simple-menu-lite{
    .MuiPopover-paper{
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
      background-color:#fff;
      ul{
        li{
           color: var(--text-body);
           min-height: auto;
           font-size:14px;
           .material-icons{
                color: var(--text-muted);
            }
           &:hover{
            background-color:var(--primary);
            color: $white;
            .material-icons{
                color: $white !important;
            }
            svg{
              fill: $white;
            }
           }
        }
      }
    }
  }
