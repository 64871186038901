$white:#ffffff;
.settings_view {  
    .box_portlet {
        .box_header {
            .material-icons {
                vertical-align: middle;
                opacity: 0;
                font:var(--font-icon);
                color: var(--text-muted);

                &:hover {
                    color: var(--error);
                }
            }
        }

        &:hover {
            .box_header {
                .material-icons {
                    opacity: 1;
                }
            }
        }
    }
}

.sidenav {
    .side_menu {
        height: 100%;
        overflow-y: auto;

        .MuiListItem-button {
            &:not(.MuiListItem-alignItemsFlexStart) {
                border-bottom: 1px solid #EAF0F2;                
            }
            &.active{
                background-color: var(--bg-default);
                .MuiListItemText-primary {
                    color: var(--primary);
                }
            }
            &.MuiListItem-gutters{
                padding-left: 0;
                .first_level{
                    padding-left: 16px;
                }
                .second_level{
                    padding-left: 32px;
                }
                .third_level {
                    padding-left: 48px;
                }
            }
            .MuiListItemText-primary {
                font-size: 13px;
                font-weight: 500;
            }

            &:hover {
                background-color: #fafafa;
                color: var(--primary);
            }
        }

        .MuiCollapse-entered {
            border-bottom: 1px solid #EAF0F2;
            .MuiCollapse-wrapperInner{
                .MuiListItem-button {
                    &:not(.MuiListItem-alignItemsFlexStart) {
                        border-top: 1px solid #EAF0F2;
                        border-bottom: 0;
                    }
                }
            }
            .MuiCollapse-entered {
                border-bottom: 0;
                border-top: 1px solid #EAF0F2;
                .MuiListItem-button {
                        border-bottom: 0;
                }
            }
        }

        .MuiListItemText-inset {
            margin: 0;
            font-size: 13px;
        }
    }
}

.settings_tabs {
    border-bottom: 1px solid #e8e8e8;
    .MuiTab-root {
        min-width: auto;
        padding: 10px 20px;        
    }
}

.table__scroll {
    overflow-y: scroll !important;
}

.align__right {
    text-align: right;
}

.settings_view {
    .pipeline_list_table {
        thead {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            position: -webkit-sticky;
            position: sticky;
            z-index: 500;
            top: 0;
            background-color: #fff;

            th {
                box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.paper-bx {
    .material-icons {
        font-size: 15px;
    }

    h6 {
        font-size: 13px;
        color: var(--text-muted);
    }

    p {
        color: var(--text-muted);
        font-size: 14px;
        line-height: 18px;
    }
}

.text-lable {
    color: var(--text-muted);
    font:var(--font-label);

    .material-icons {
        font-size: 14px;
        vertical-align: middle;
    }
}

.text_link {
    &:hover {
        text-decoration: underline;
    }
}

.dropdown_options {
    list-style: none;
    position: relative;
    margin: 5px 0;
    padding: 0;

    li {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 10px;

        .drag-handle {
            cursor: url(https://mail.google.com/mail/images/2/openhand.cur), move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab, -moz-grab, url(https://mail.google.com/mail/images/2/openhand.cur), move;
        }

        .material-icons {
            vertical-align: middle;
            opacity: 0;
            font:var(--font-icon);
            color: var(--text-muted);

            &:hover {
                color: var(--error);
            }
        }

        &:hover {
            .material-icons {
                opacity: 1;
            }
        }
    }
}

.currency_dropdown,
.choose_field_menu {
    .MuiPopover-paper {
        max-height: 300px;
    }
}

.paper_bx {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 16px;
    padding: 5px 16px;

    .automation_icon {
        .material-icons {
            color: var(--text-muted);
            font-size: 44px;
            font-weight: 700;
        }
    }

    .automation_details {
        h6 {
            font-size: 13px;
            margin: 0 0 5px;
        }

        p {
            color: var(--text-body);
            font-size: 13px;
            line-height: 18px;
            margin: 0;
        }
    }

    &+.paper_bx {
        border-top-width: 0;
        ;
    }
}

.settings_view {
    .MuiInputBase-input {
        font:var(--font-input);
        color: var(--text-body);
    }

    @mixin placeholder {
        font:var(--font-input);
        color: var(--text-hint);
    }

    .select_trigger {
        border-bottom: 1px solid #EAF0F2;

        .trigger_modules {
            width: 200px;
            border-right: 1px solid #EAF0F2;
        }

        .text-lable {
            color: var(--text-muted);
            font:var(--font-label);
            padding: 7px 16px;
            display: block;
        }
    }

    .workflow_trigger {
        .MuiTabs-vertical {
            border-right: 1px solid #EAF0F2;
            width: 200px;

            .trigger_button {
                text-align: left;
                min-height: inherit;
                text-transform: inherit;
                border-bottom: 1px solid #EAF0F2;
                font-size: 14px;
                font-weight: 500;
                padding-right: 10px;

                span.MuiTab-wrapper {
                    display: inline-block;
                    position: relative;
                    padding-right: 50px;

                    .material-icons {
                        vertical-align: middle;
                        margin-top: -3px;
                        margin-right: 16px;
                        color: var(--text-muted);

                        &.tabicon_active {
                            opacity: 0;
                            position: absolute;
                            right: 0;
                            margin-right: 0;
                            top: 50%;
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: -8px;
                        }
                    }
                }

                &.Mui-selected {
                    span.MuiTab-wrapper {
                        .material-icons {
                            color: var(--primary);

                            &.tabicon_active {
                                color: var(--green);
                                opacity: 1;
                            }
                        }
                    }
                }

                &:last-child {
                    border-bottom-width: 0;
                }

                &:hover {
                    background-color: #fafafa;
                }
            }
        }

        .trigger_panel {
            .MuiBox-root {
                padding: 0;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        border-bottom: 1px solid #EAF0F2;
                        padding: 8.8px 50px 8.8px 16px;
                        cursor: pointer;
                        position: relative;

                        h6 {
                            font-size: 13px;
                            margin: 0 0 5px;
                        }

                        p {
                            color: var(--text-muted);
                            font-size: 14px;
                            line-height: 18px;
                            margin: 0;
                        }

                        &:last-child {
                            border-bottom-width: 0;
                        }

                        &:hover {
                            background-color: #fafafa;
                        }

                        .panelicon_active {
                            opacity: 0;
                            position: absolute;
                            right: 35px;
                            margin-right: 0;
                            top: 50%;
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: -8px;
                        }

                        &.trigger_active {
                            background-color: #fafafa;

                            .panelicon_active {
                                color: var(--green);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.task_trigger {
            .trigger_panel {
                .MuiBox-root {
                    ul {
                        li {
                            padding: 12.7px 50px 12.7px 16px;
                        }
                    }
                }
            }
        }
    }
    .expansion_panel{
        border: 1px solid #ebedf2;
        box-shadow: none;
        border-radius: 0;
        background-color:var(--bg-default);
        margin-bottom: 16px;
        position: relative;
        &:first-child{
            border-radius: 0;
        }
        &:before{
            display: none;
        }
        .panel_heading{
            padding: 0 16px;
            min-height: auto;
            position: relative;
            >div{
                margin: 0;
                padding:0;
                &.MuiExpansionPanelSummary-expandIcon{
                    position: absolute;
                    right: 15px;
                    top: 10px;
                }
            }
        }
        .panel_summary{
            font-weight: 500;
            font-size: 15px;    
            padding: 10px 0;          
        }
        .expansion_icon{
            margin-right: 10px;
            vertical-align: middle;
            color: var(--text-muted);
            margin-top: -3px;
            transition: all .1s ease-in-out;
            -moz-transition: all .1s ease-in-out;
            -webkit-transition: all .1s ease-in-out;
        }        
        .panel_details{    
            padding: 10px 16px;       
            .custom-checkbox{
                margin-left: 0px;
                margin-right: 16px;
                .MuiFormControlLabel-label{
                    width: 100%;
                }
                .MuiCheckbox-root{
                    padding: 4px;
                    margin-left: 5px;
                }
            }
        }
        &.Mui-expanded{
            background-color: var(--bg-paper);
            .panel_summary{
                display: none;
            }
            .panel_details{
                font-size: 20px;
                .expansion_icon{
                    color: var(--primary);
                    font-size: 44px;
                }
            }
        }
    }
}

.filter_conitions {
    list-style: none;
    position: relative;
    margin: 5px 0;
    padding: 0;

    li {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 10px;

        .delete_icon {
            vertical-align: middle;
            opacity: 0;
            font:var(--font-icon);
            color: var(--text-muted);

            &:hover {
                color: var(--primary);
            }
        }

        &:hover {
            .delete_icon {
                opacity: 1;
            }
        }

        .choose_field_option {
            li {
                padding: 0;
                margin-bottom: 0;
                cursor: pointer;

                p {
                    font-size: 14px;
                    color: var(--text-body);
                }

                .material-icons {
                    color: var(--text-muted);
                    font:var(--font-icon);
                }
            }
        }

        .filter_index {
            flex: 0 0 40px;
            display: flex;
            justify-content: left;
            padding: 0 10px 0 0;
            font-size: 13px;
            font-weight: 500;
        }

        .choose_field_option {
            display: inline-block;
            padding: 0;
        }

        .flex-fixed {
            flex: 1 1 100px;
        }
    }
}

.filter_conditions {
    button {
        width: 50px;
        background-color: transparent;
        border-style: dashed;

        &.button_active {
            background-color: var(--green);
            color: $white;
            border-style: solid;
        }
    }
}

.add_more_field {
    .material-icons {
        vertical-align: middle;
        opacity: 0;
        font:var(--font-icon);
        color: var(--text-muted);

        &:hover {
            color: var(--error);
        }
    }

    &:hover {
        .material-icons {
            opacity: 1;
        }
    }
}

.task_automation {
    .paper_bx {
        padding: 5px;
        position: relative;

        p {
            margin: 3px;

            &.text-mute {
                font-size: 12px;
            }
        }

        .list_action {
            position: absolute;
            right: 0;
        }
    }
}

.simple-menu-lite {
    .action_icon {
        font-size: 1.5rem;
        color: var(--text-muted);
        margin-right: 10px;
    }
}

.set_task {
    padding: 16px;
    position: relative;

    &+.set_task {
        border-top: 1px solid #ebedf2;
    }

    .delete_settask {
        position: absolute;
        right: 16px;
        top: 5px;
        z-index: 5;
        .material-icons {
            vertical-align: middle;
            opacity: 0;
            font:var(--font-icon);
            color: var(--text-muted);

            &:hover {
                color: var(--error);
            }
        }
    }

    &:hover {
        .delete_settask {
            .material-icons {
                opacity: 1;
            }
        }
    }
}
.setting_goal{
    .MuiBox-root{
        padding: 0;
    }
    .bg-default{
        padding: 8px 10px;
    }
}
.simple_table{
    &.h-vh-auto{
        height: calc(100vh - 410px);
        tbody{
            td{
                vertical-align: top;
            }
        }
    }
    table{
        thead{
            th{
                font-size: 13px;
                font-weight: 500;
                padding:13px 10px;
                color: #696E6F;
                border-bottom:0;
            }
        }
        tbody{
            td{
                padding:5px 10px;
                border-bottom: 0;
                border-top: 1px solid rgba(224, 224, 224, 1);
                h6 {
                    font-size: 13px;
                    color: var(--text-muted);
                    margin-bottom: 5px;
                }
            
                p {
                    color: var(--text-muted);
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0;
                }
            }
        }
        tfoot{
            td{
                padding:8px;
                border-bottom: 0;
                font-size: 14px;
                font-weight: 500;
                color: var(--text-muted);
                border-top: 1px solid rgba(224, 224, 224, 1);
                background-color: var(--bg-default);
            }
        }
    }
}
.amount_button{
    .MuiIconButton-sizeSmall{
        margin-right: -27px;
        .material-icons{
            opacity: 0;
            font:var(--font-icon);
            color: var(--text-muted);
        }
    }
    &:hover{
        .MuiIconButton-sizeSmall{
            .material-icons{
                opacity: 1;
            }
        }
    }
}
.display__block{
    display: block !important;
}

.padding__0{
    padding: 0px !important;
}

.profile_wrap
{
    max-height: 90%;
    overflow: scroll !important;
}
.card-margin-leadstatus
{
    margin-bottom: 20px !important;
}
.width-100
{
    width: 100% !important;
}
.crm-fontawsome-icon {
    margin-right: 10px;
    font-size: 1.10rem;
    color: var(--text-body);
}
.crm-custom-field-table {
    margin-bottom: 50px;

    .crm-custom-field-fieldlabel {
        margin-left: 10px;
    }
    
    .align_left {
        text-align: left !important;
    }

    .align_right {
        text-align: right !important;
    }

}
.crm-lead-status-list-ul
{
    max-height:calc(100vh - 410px);
    overflow: auto;
}