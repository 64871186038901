$white: #ffffff;
.lead_content {
    position: fixed;
    left: 416px;
    right: 382px;
    top: 60px;
    bottom: 0;
    padding: 15px 0;
    @include media-below(xl) {
        left: 310px;
        right: 328px;
    }
    &.settings_view {
        left: 310px;
    }
}
.lead_tabs {
    border-bottom: 1px solid #e8e8e8;
    .MuiTabs-flexContainer {
        justify-content: space-between;
        .MuiTab-textColorPrimary {
            @include media-below(xl) {
                min-width: auto;
            }
        }
    }
}
.tab_card {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;
    top: 98px;
}
.v_scroll {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}
.v_scroll .tab_card .MuiBox-root {
    height: calc(100vh - 260px);
}
.box_portlet {
    border: 1px solid #ebedf2;
    &.border-0 {
        border: 0;
        .box_header {
            border: 1px solid #ebedf2;
            border-bottom: 0;
        }
    }
    .box_header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border-bottom: 1px solid #ebedf2;
        min-height: 45px;
        background-color: #fafafa;
        padding: 0 16px;
        .box_label {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-line-pack: flex-first;
            align-content: flex-first;
            .box_title {
                margin: 0;
                padding: 0;
                font: var(--font-body-2);
            }
        }
        .box_tools {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-line-pack: end;
            align-content: flex-end;
        }
    }
    .box_body {
        padding: 16px;
        .lead_pl {
            position: relative;
            padding-left: 22px;
            .icon_left {
                position: absolute;
                left: 0;
                font-size: 1.3rem;
                color: var(--primary);
                top: 0;
            }
            .font-size-14 {
                font-size: 14px;
            }
            .MuiFab-secondary {
                background-color: var(--secondary);
                box-shadow: none;
                width: 32px;
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: rgb(178, 110, 46);
                }
            }
            .bg-grey {
                background-color: #e0e0e0;
                box-shadow: none;
                width: 32px;
                color: var(--text-body);
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: var(--primary);
                    color: #fff;
                }
            }
            .bg-success {
                background-color: #08ad6c;
                box-shadow: none;
                width: 32px;
                color: #fff;
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: #048653;
                }
            }
            .bg-grey_sidebar {
                text-decoration: none;
                background-color: #d5d5d5;
                width: 40px;
                height: 40px;
            }
            .activity_icon_normal {
                display: inherit;
                font-size: 20px;
                color: #7467ef;
            }
            .bg-success-task_sidebar {
                background-color: #08ad6c;
                width: 40px;
                color: #fff;
                height: 40px;
                &:hover {
                    background-color: #048653;
                    .hover_icon {
                        display: block;
                    }

                    .activity_icon {
                        display: none;
                    }
                }

                .activity_icon {
                    display: inherit;
                    font-size: 20px;
                    color: #fff !important;
                }
                
                .hover_icon {
                    display: none;
                    color: #fff !important;
                }
            }
        }
    }
}

.label-size-14 {
    label {
        font: var(--font-label);
    }
}
.vert-middle {
    vertical-align: middle;
    line-height: 20px;
}
.border_bx {
    padding: 10px 30px 10px 10px;
    border: 2px solid #ebedf2;
    margin-top: 5px;
    position: relative;
    font-size: 14px;
    .hover_show {
        position: absolute;
        opacity: 0;
        right: 5px;
        top: 5px;
    }
    &:hover .hover_show {
        opacity: 1;
    }
}
.assign_list {
    p {
        color: var(--text-body);
        font-size: 1rem;
    }
}
.ml-auto {
    margin-left: auto !important;
}
.lead_right {
    position: fixed;
    right: 16px;
    width: 350px;
    top: 60px;
    bottom: 0;
    padding: 15px 0;
    overflow-y: auto;
    @include media-below(xl) {
        width: 295px;
    }
    .lead_pl {
        position: relative;
        .hover_show {
            position: absolute;
            opacity: 0;
            right: -5px;
            top: 0;
        }
        &:hover .hover_show {
            opacity: 1;
        }
    }
    .box_portlet {
        &:hover{
            background-color: rgba(233, 231, 228, 0.4);
        }
        .MuiAvatar-colorDefault{
            background-color: #e0e0e0;
        }
        border: none;
        .box_header {
            min-height: 34px;
            padding: 0 10px;
            .box_title {
                font: var(--font-panel-heading);
            }
        }
        .box_body {
            padding: 10px 16px;
            .opportunity-progress {
                height: 6px;
                margin-top: 5px;
                &.primary {
                    background-color: rgba(116, 103, 239, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--primary);
                    }
                }
                &.yellow {
                    background-color: rgba(242, 191, 88, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--yellow);
                    }
                }
                &.secondary {
                    background-color: rgba(255, 158, 67, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--secondary);
                    }
                }
                &.green {
                    background-color: rgba(8, 173, 108, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--green);
                    }
                }
            }
        }
        .dorpdown_menu {
            li {
                padding-left: 0;
                .dorpdown_menu_list {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
.box_action {
    position: relative;
    .hover_show {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        right: 3px;
        bottom: 3px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        background-color: #fff;
    }
    &:hover .hover_show {
        visibility: visible;
        opacity: 1;
    }
    .bg-body {
        background-color: var(--text-body);
    }
}
button.btn_Primary {
    color: var(--bg-paper);
    background-color: var(--primary);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042),
        0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    &:hover {
        background-color: rgb(81, 72, 167);
        color: var(--bg-paper);
    }
}
button.btn-primary {
    background-color: #fff;
    color: var(--text-body);
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    margin: 0 10px 3px;
    border: 1px solid #c9c9c9;
    box-shadow: none;
    @include media-below(xl) {
        margin: 0 5px 3px;
    }
    .material-icons {
        font-size: 1.3rem;
        color: var(--primary);
    }
    &:hover {
        background-color: var(--bg-default);
    }
}
div.MuiPickersToolbar-toolbar {
    background-color: var(--bg-paper);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
        padding-left: 24px;
        padding-right: 24px;
        justify-content: left;
    }
}
.sidenav {
    top: 60px;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: fixed;
    overflow: hidden;
    z-index: 99;
    left: 0;
    box-shadow: var(--elevation-z8);
    width: 400px;
    background-color: var(--bg-paper);
    @include media-below(xl) {
        width: 295px;
    }
    &.collepse_menu {
        width: 295px;
    }
    .sidenav__user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 1rem 1rem;
        .username-photo {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                height: 4rem;
                width: 4rem;
                border-radius: 50%;
                margin-bottom: 8px;
                max-width: 100%;
            }
            .username {
                font-size: 16px;
                color: var(--text-body) !important;
                font-weight: 500;
                margin-right: -26px;
                .filled_star {
                    .material-icons {
                        color: #f2bf58;
                        font-size: 16px;
                    }
                }
            }
            .user_designation {
                color: var(--text-body) !important;
                font-weight: 300;
            }
        }
    }
    .input_bx {
        > div {
            &:before {
                border-color: transparent;
            }
        }
    }
    .MuiInputBase-input {
        font: var(--font-input);
    }
    .v_scroll {
        height: calc(100vh - 340px);
        &.no_button {
            height: calc(100vh - 310px);
        }
    }
}
.cursor_poiter {
    cursor: pointer;
}
.button_size {
    width: 30px;
    height: 30px;
}
.lead_pl {
    .hover_show {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }
    &:hover .hover_show {
        visibility: visible;
        opacity: 1;
    }
}
.edit_bx {
    font-weight: 500;
    color: var(--text-body);
    font-size: 1rem;
    margin-left: 3px;
    border: 1px solid transparent;
    padding: 6px;
    position: relative;
    min-height: 38px;
    border-radius: 4px;
    .edit_hover_show {
        position: absolute;
        opacity: 0;
        right: 5px;
        top: 2px;
    }
    &:hover .edit_hover_show {
        opacity: 1;
    }
    &:hover {
        border-color: #ebedf2;
        background-color: #fafafa;
        cursor: text;
    }
}
.dialog_popup {
    .dialog_header {
        padding: 10px 16px;
        border-bottom: 1px solid #ebedf2;
    }
    .dialog_body {
        padding: 10px 16px;
        .quill {
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            display: -ms-flexbox;
            display: flex;
            .ql-container.ql-snow {
                border-top: 1px solid #ccc;
                border-bottom-color: transparent;
                position: relative;
                .ql-blank {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
            .ql-toolbar.ql-snow {
                .ql-picker-options {
                    top: auto;
                    bottom: 100%;
                }
            }
        }
    }
    .dialog_footer {
        padding: 10px 16px;
    }
}
.aside_left {
    position: fixed;
    left: 16px;
    width: 260px;
    bottom: 0;
    top: 80px;
    .filter_menu {
        ul {
            list-style: none;
            margin: 10px 0;
            padding: 0;
            li {
                a {
                    color: var(--text-body);
                    padding: 8px 10px 8px 30px;
                    display: block;
                    &:hover {
                        background-color: #fafafa;
                        color: var(--primary);
                    }
                }
            }
        }
    }
    .filter_menu_custom {
        ul {
            list-style: none;
            margin: 10px 0;
            padding: 0;
            li {
                div {
                    color: var(--text-body);
                    padding: 2px 10px 8px 30px;
                    display: inline;
                    cursor: pointer;
                    width: 80%;
                    &:hover {
                        background-color: #fafafa;
                        color: var(--primary);
                    }
                }
            }
        }
    }
    .fixed_height {
        bottom: 16px;
        //position: absolute;
        position: absolute;
        //top: 363px;
        top: 253px;
        left: 0;
        right: 0;
        padding-bottom: 70px !important;
        &.LeadList_collapse {
            top: 64px;
        }
        .v_scroll {
            height: calc(100% - 35px);
        }
    }
}
.text_link {
    color: #2b41b2;
    font-size: 12px;
    font-weight: 500;
}
.fixed-bottom {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #ebedf2;
    left: 0;
    right: 0;
    padding: 10px 16px 0;
}
.fixed_layout {
    position: fixed;
    left: 291px;
    top: 80px;
    bottom: 16px;
    right: 16px;
    padding: 16px 0;
    .circular-image-small {
        vertical-align: middle;
        margin-right: 15px;
    }
    .text_ellipsis {
        max-width: 175px;
        text-align: left;
        a {
            &:hover {
                text-decoration: underline;
                color: var(--text-body);
            }
        }
    }
}
.leads_list_table {
    max-height: 100%;
    > div {
        height: 100%;
    }
    .MuiInputBase-adornedStart {
        height: 40px;
    }
    .list_table_header {
        & + div {
            max-height: calc(100vh - 225px);
            border-top: 1px solid #ebedf2;
        }
    }
    thead {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: -webkit-sticky;
        position: sticky;
        z-index: 400;
        top: 0;
        background-color: #fff;
        th {
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
            position: -webkit-sticky;
            position: sticky;
            z-index: 400;
            top: 0;
            background-color: #fff;
            box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
            span {
                color: #696e6f;
                overflow: inherit;
                text-overflow: inherit;
            }
            &:nth-child(1),
            &:nth-child(2) {
                z-index: 402;
            }
        }
        tr {
            &:first-child {
                th {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 8px 10px;
                text-align: left;
            }
            .MuiCheckbox-colorSecondary {
                opacity: 0;
            }
            .text-secondary,
            .user_info {
                opacity: 0;
                width: 0;
            }
            .text_ellipsis {
                min-width: 120px;
            }
            .icon_visible {
                opacity: 1;
                width: auto;
            }
            &:hover {
                td {
                    background-color: #f5f5f5;
                }
                .MuiCheckbox-colorSecondary {
                    opacity: 1;
                }
                .text-secondary,
                .user_info {
                    opacity: 1;
                    width: auto;
                }
                .text_ellipsis {
                    width: 120px;
                }
            }
            .Mui-checked {
                opacity: 1;
            }
        }
    }
    .MuiInputBase-adornedStart {
        &.Mui-focused {
            .MuiInputBase-inputAdornedStart {
                width: 200px;
            }
        }
    }
    .avatar_circle {
        display: inline-block;
        height: 36px;
        width: 36px;
        vertical-align: middle;
        margin-right: 15px;
        text-align: center;
        line-height: 36px;
        color: $white;
        &.color_a {
            background-color: #ab0cff;
        }
        &.color_b {
            background-color: #3283ff;
        }
        &.color_c {
            background-color: #85660c;
        }
        &.color_d {
            background-color: #782ab7;
        }
        &.color_e {
            background-color: #565656;
        }
        &.color_f {
            background-color: #1c8356;
        }
        &.color_g {
            background-color: #17ff32;
            color: var(--text-body);
        }
        &.color_h {
            background-color: #f8e2a1;
            color: var(--text-body);
        }
        &.color_i {
            background-color: #e3e3e3;
            color: var(--text-body);
        }
        &.color_j {
            background-color: #1cbf4e;
        }
        &.color_k {
            background-color: #c5441c;
        }
        &.color_l {
            background-color: #dfa1fe;
            color: var(--text-body);
        }
        &.color_m {
            background-color: #fc00f8;
        }
        &.color_n {
            background-color: #325a9b;
        }
        &.color_o {
            background-color: #ffaf16;
        }
        &.color_p {
            background-color: #f6a5a0;
        }
        &.color_q {
            background-color: #91ad1c;
        }
        &.color_r {
            background-color: #f7222e;
        }
        &.color_s {
            background-color: #1cffcf;
        }
        &.color_t {
            background-color: #2ed9ff;
        }
        &.color_u {
            background-color: #b10ca1;
        }
        &.color_v {
            background-color: #c174a7;
        }
        &.color_w {
            background-color: #fe1cbf;
        }
        &.color_x {
            background-color: #b10068;
        }
        &.color_y {
            background-color: #fae326;
        }
        &.color_z {
            background-color: #fb0087;
        }
    }
}
.white_space_nowrap {
    white-space: nowrap;
    .bg-success {
        background-color: #08ad6c;
        width: 40px;
        color: #fff;
        height: 40px;
        &:hover {
            background-color: #048653;
        }
        .activity_icon {
            display: inherit;
            font-size: 20px;
            color: #fff !important;
        }

        .hover_icon {
            display: none;
            color: #fff !important;
        }
    }

    .wrap_icons_task {
        .activity_icon {
            display: inherit;
            font-size: 20px;
            color: #7467ef;
        }
        .hover_icon {
            display: none;
            color: #7467ef;
        }
        &:hover {
            .hover_icon {
                display: block;
            }

            .activity_icon {
                display: none;
            }
        }
    }
    .avatar_circle {
        display: inline-block;
        height: 36px;
        width: 36px;
        vertical-align: middle;
        text-align: center;
        line-height: 36px;
        color: $white;

        &.color_a {
            background-color: #ab0cff;
        }

        &.color_b {
            background-color: #3283ff;
        }

        &.color_c {
            background-color: #85660c;
        }

        &.color_d {
            background-color: #782ab7;
        }

        &.color_e {
            background-color: #565656;
        }

        &.color_f {
            background-color: #1c8356;
        }

        &.color_g {
            background-color: #17ff32;
            color: var(--text-body);
        }

        &.color_h {
            background-color: #f8e2a1;
            color: var(--text-body);
        }

        &.color_i {
            background-color: #e3e3e3;
            color: var(--text-body);
        }

        &.color_j {
            background-color: #1cbf4e;
        }

        &.color_k {
            background-color: #c5441c;
        }

        &.color_l {
            background-color: #dfa1fe;
            color: var(--text-body);
        }

        &.color_m {
            background-color: #fc00f8;
        }

        &.color_n {
            background-color: #325a9b;
        }

        &.color_o {
            background-color: #ffaf16;
        }

        &.color_p {
            background-color: #f6a5a0;
        }

        &.color_q {
            background-color: #91ad1c;
        }

        &.color_r {
            background-color: #f7222e;
        }

        &.color_s {
            background-color: #1cffcf;
        }

        &.color_t {
            background-color: #2ed9ff;
        }

        &.color_u {
            background-color: #b10ca1;
        }

        &.color_v {
            background-color: #c174a7;
        }

        &.color_w {
            background-color: #fe1cbf;
        }

        &.color_x {
            background-color: #b10068;
        }

        &.color_y {
            background-color: #fae326;
        }

        &.color_z {
            background-color: #fb0087;
        }
    }
}
.text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}
.text_link {
    color: var(--primary);
    &:hover {
        text-decoration: underline;
    }
}
.more_vert_menu {
    position: absolute;
    right: 0;
    top: 0;
}
.MuiFab-sizeSmall.small-size {
    width: 32px;
    height: 32px;
    min-height: 32px;
    box-shadow: none;
}
.list_user_profile {
    max-width: 250px;
    margin: 10px auto;
    h5 {
        color: var(--text-body);
        margin-bottom: 5px;
    }

    p {
        color: var(--text-muted);
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }
}

.user__menu {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.rightbar_vscroll {
    position: relative;
    height: calc(100vh - 330px);
    .MuiBox-root {
        height: 100%;
        position: relative;
        padding: 0;
        .v_scroll {
            height: 100%;
            padding-left: 16px;
            padding-top: 16px;
        }
        .MuiList-padding {
            li {
                padding: 0 0 0 10px;
            }
        }
    }
    .input_bx {
        > div {
            &:before {
                border-color: transparent;
            }
        }
    }
    .MuiInputBase-input {
        font: var(--font-input);
    }
    .sidebar_activity {
        list-style: none;
        padding: 0;
        margin: 0;
        .MuiAvatar-colorDefault {
            background-color: var(--text-muted);
            color: $white;
        }
        h6 {
            font-size: 15px;
            color: var(--text-body);
            margin-bottom: 5px;
        }
        p {
            color: var(--text-muted);
            font-size: 14px;
            line-height: 18px;
            margin: 0;
        }
        li {
            border-bottom: 1px solid #ebedf2;
            padding: 16px;
            position: relative;
            &:hover {
                .activity_action {
                    display: block;
                }
            }
        }
    }
    .activity_summary {
        border-top: 1px solid #ebedf2;
        border-bottom: 1px solid #ebedf2;
        margin-top: 16px;
        .MuiGrid-item {
            border-right: 1px solid #ebedf2;
            padding: 5px;
            p {
                font-size: 14px;
            }
        }
    }
}
.rightbar_vscroll_task {
    position: relative;
    height: calc(100vh - 220px);
}
.rightbar_related {
    .MuiCard-root {
        box-shadow: none;
        border-bottom: 1px solid #ebedf2;
        margin-top: 0 !important;
        border-radius: 0;
        .box_portlet {
            border: 0;
            .box_body {
                padding: 0 !important;
                .hover_show {
                    position: absolute;
                    opacity: 0;
                    right: -5px;
                    top: -5px;
                }
                &:hover .hover_show {
                    opacity: 1;
                }
            }
        }
    }
}
.activity_action {
    position: absolute;
    display: none;
    right: 10px;
    top: 10px;
    z-index: 1;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 32px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.12);
}
.custom_dialog {
    top: auto !important;
    width: 750px;
    margin: 0 auto;
    .MuiDialog-paperWidthMd {
        max-width: 750px;
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.5);
    }
    .MuiDialog-container {
        height: inherit;
    }
    .MuiDialog-scrollPaper {
        align-items: flex-end;
    }
    .MuiBackdrop-root {
        display: none;
    }
    &.alignitemsend {
        top: calc(100% - 120px) !important;
        max-width: 415px;
        .MuiDialog-paperWidthMd {
            position: absolute;
            top: calc(100% - 88px);
            max-width: 350px;
        }
        .dialog-body,
        .dialog-action {
            display: none;
        }
    }
    &.alignitemsend-fullscreen {
        .MuiDialog-paperWidthMd {
            max-width: 350px;
        }
    }
    &.fullscreen {
        top: 0 !important;
        width: auto;
        .MuiDialog-paperWidthMd {
            max-width: 80%;
            margin-bottom: 0;
            border-radius: 8px;
        }
        .MuiDialog-scrollPaper {
            align-items: center;
        }
        .MuiDialog-container {
            height: 100%;
        }
        .MuiBackdrop-root {
            display: block;
        }
    }
    .rotate-45 {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .dialog-body {
        padding: 5px 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        flex: 1 1 auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 200px);
        .MuiInputBase-input {
            font-size: 15px;
            color: var(--text-body);
        }
    }
    .dialog-body-no-scroll {
        padding: 5px 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        flex: 1 1 auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 200px);
        .MuiInputBase-input {
            font-size: 15px;
            color: var(--text-body);
        }
    }
    .dialog-action {
        padding: 16px;
    }
}
.input_bx {
    .MuiInputLabel-animated {
        font-size: 12px;
        font-weight: 500;
        color: var(--text-muted);
        &.MuiFormLabel-filled,
        &.Mui-focused {
            transform: translate(0, 1.5px) scale(0.98);
        }
    }
    .dorpdown_menu_list {
        padding: 0;
        margin: 0;
        p {
            cursor: pointer;
            color: var(--text-muted);
            font-size: 12px;
            font-weight: 500;
        }
        li {
            padding: 0;
        }
        .material-icons {
            color: var(--text-muted);
            font-size: 18px;
        }
    }
}
.multiple-text-dropdown {
    font-size: 12px;
    padding-right: 25px;
    outline: none;
    // padding-bottom: 0px;
}

.add-more-field {
    float: right;
    color: #7467ef;
    font-size: 12px;
    cursor: pointer;
}
.checkbox-margin-18 {
    margin-top: 18px !important;
}
.sidebar_activity.crm-leads-sidebar-logs-list {
    max-height: calc(100vh - 450px);
    overflow: auto;
}
.sidebar_activity.crm-leads-sidebar-logs-list-task {
    max-height: calc(100vh - 320px);
    overflow: auto;
}
.crm-add-contat-company-input {
    z-index: 5 !important;
}
.lead-filter-error {
    font-size: 13px;
    color: red;
    margin-right: 35px !important;
}
.custom-avatar-sidebar {
    cursor: pointer !important;
    height: 4rem !important;
    width: 4rem !important;
    border-radius: 50% !important;
    margin-bottom: 8px !important;
    max-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.loaderPopupCenter {
    display: inline-block !important;
    vertical-align: middle !important;
    text-align: center !important;
    top: 50% !important;
    position: absolute !important;
    left: 50% !important;
}

.popupHeightCustom {
    height: 400px !important;
}
.pipline-selected-class {
    background-color: #fafafa !important;
    color: #7467ef !important;
}

.crm-email-log-attachment {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.06);
    cursor: pointer;
    box-shadow: none !important;
    margin: 5px !important;
    font-size: small;
}

.crm_log_checkbox_filter {
    position: absolute !important;
    right: 10px !important;
}

.crm_log_checkbox_filter {
    position: absolute !important;
    right: 20px !important;
    &:checked {
        color: #fff !important;
        &:hover {
            color: #fff !important;
        }
    }
}

.visibleStarIcon {
    opacity: 1 !important;
    width: auto !important;
}

.crm_activitytype_icon {
    font-size: "15px";
    color: "#7467ef";

    &:hover {
        color: #ffffff;
    }
}

.wrap_icons_task_sidebar {

    box-shadow: none !important;

    .activity_icon {
        display: inherit;
        font-size: 20px;
        color: #7467ef;
    }

    .hover_icon {
        display: none;
        color: #7467ef;
    }

    &:hover {
        .hover_icon {
            display: block;
        }

        .activity_icon {
            display: none;
        }
    }
}
.crm-custom-notification-settings,
.crm-custom-importdata-settings {
    height: calc(100% - 20px);

    .default-template-link {
        color: var(--primary);
    }

    .import-buttons {
        .types-buttons {
            height: 60px;
            width: 140px;
        }
    }


    .crm-custom-notification-table {
        .antd-tables {

            thead,
            th,
            td {
                box-shadow: none !important;
            }

            td {
                border: none !important;
                // padding-bottom: 20px !important;
            }

            .align_left {
                text-align: left !important;
            }

        }
    }

    .MuiSnackbar-anchorOriginBottomLeft {
        left: auto !important;
    }
}

.crm-calendar-event {
    a {
        color: var(--primary) !important;
    }

}

.crm-custom-suggestion-autocomplete {

    .custom-label,
    .custom-email {
        font-size: 12px !important;
    }
}

// email template editor 
.crm_dialog_create_template {
    .MuiPaper-root {
        overflow: visible !important;
    }

    .megadraft-editor {
        .paragraph + .paragraph {
            margin: 0 !important;
        }
        .sidebar__menu {
            float: right !important;
            left: unset !important;
            right: 20px !important;
            z-index: 10 !important;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2) !important;
            border-radius: 25px !important;
            padding-left: 3px !important;
            padding-right: 3px !important;
            background-color: #fff !important;
        }
        .toolbar__button {
            height: auto !important;
        }
    }
    
}
.subject-editor{
    .DraftEditor-root {
        max-height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        font-size: 13px !important;
        overflow: auto;
    }
}

.wrapper-rec {
    &:hover{
        background-color: #DCE3E6 !important;
    }
    .crm-rec-hashplaceholder {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        background-color: #DEE3FF !important;
        color: #1E3192 !important;
        font-size: 12px !important;
        border-radius: 30px !important;
    }
}

.wrapper-send {
    &:hover {
        background-color: #DCE3E6 !important;
    }
    .crm-send-hashplaceholder {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        background-color: #DEFFF1 !important;
        color: #07A661 !important;
        font-size: 12px !important;
        border-radius: 30px !important;
    }
}

a[title*="rec"]{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: #DEE3FF !important;
    color: #1E3192 !important;
    font-size: 12px !important;
    border-radius: 30px !important;
}
a[title*="send"] {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: #DEFFF1 !important;
    color: #07A661 !important;
    font-size: 12px !important;
    border-radius: 30px !important;
}

.message-editor {
    .DraftEditor-root {
        min-height: 100px;
        max-height: 30px;
        font-size: 13px !important;
        overflow: auto;
    }
}



///---------------------design feedback-----------------------------
.ant-empty.ant-empty-normal{
    text-align: center !important;
    margin-top: 20px !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before
{
    border-bottom: 1px solid #DCE3E6 !important;
}

.MuiInputLabel-animated{
    font-size: 12px !important;
    color: #696E6F !important;
}
.MuiInput-input.MuiInputBase-input{
    font-size: 13px !important;
    // color: #696E6F !important;
}
.crm-custom-bg-primary{
    .text-white{
        color: #000 !important;
    }
    .bg-primary{
        background-color: #f5f5f5 !important;
    }
}